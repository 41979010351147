import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Layout from '../components/layout';

import { Form, Field } from 'react-final-form';
import ArrowRightActive from '../images/arrow-right-active.svg';
import ArrowRightInactive from '../images/arrow-right-inactive.svg';
import ArrowLeftInactive from '../images/arrow-left-inactive.svg';
import ArrowLeftActive from '../images/arrow-left-active.svg';
import Submitted from '../images/done.svg';
import { motion } from 'framer-motion';

import countriesList from '../lib/countriesList';

const onSubmit = async (values) => {
  // alert(JSON.stringify(values));
  const mailSend = await axios.post('/.netlify/functions/sendmail', values);
  return mailSend;
};

const SubmittedView = ({ status, goBack }) => {
  const revealAnimation = {
    initial: {
      opacity: 0,
      y: '30px',
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    transition: {
      type: 'tween',
      durationn: 1.2,
      ease: 'easeIn',
    },
  };

  return (
    <div className="full-width-height-wrapper">
      <div className="submitted-feedback">
        {/* I have a bad feeling about using 'success' and 'fail' strings for defining form submit status.... */}
        {status == 'success' ? (
          <div className="success">
            <motion.div
              className="icon"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, ease: 'easeInOut', type: 'tween' }}
            >
              <img src={Submitted} />
            </motion.div>
            <div className="text">
              <motion.h4
                initial={revealAnimation.initial}
                animate={revealAnimation.animate}
                transition={revealAnimation.transition}
              >
                Submission Received
              </motion.h4>
              <motion.p
                initial={revealAnimation.initial}
                animate={revealAnimation.animate}
                transition={{ ...revealAnimation.transition, delay: '0.3' }}
              >
                <p>We've received your application and we'll get back to you</p>
              </motion.p>
            </div>
          </div>
        ) : (
          <div className="failure">
            <p>Something went wrong. Please try again</p>
            <span className="cred-link" onClick={() => goBack()}>
              Go back
            </span>
          </div>
        )}{' '}
      </div>
    </div>
  );
};

const FormWrapper = ({ children, initialValues, makeBgPink, onSubmit }) => {
  const [values, setValues] = useState(initialValues);
  const [page, setPage] = useState(0);
  const [entryDirection, setEntryDirection] = useState('fromRight');
  const [status, setStatus] = useState(null);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (page === 1) {
      makeBgPink(true);
    } else {
      makeBgPink(false);
    }
  }, [page]);

  // const validate = React.Children.toArray(children)[page].props.validate;
  const validate = () => {};

  const activePage = React.Children.map(children, (element, key) => {
    return React.cloneElement(element, { key });
  })[page];

  const handleNext = (val) => {
    setEntryDirection('fromRight');
    setValues({ ...val, ...values });
    setPage(Math.min(page + 1, children.length - 1));
  };

  const handlePrevious = () => {
    setEntryDirection('fromLeft');
    setPage(Math.max(0, page - 1));
  };

  const handleSubmit = (values) => {
    const isLastPage = page === children.length - 1;
    if (isLastPage) {
      setIsSubmitting(true);
      onSubmit(values)
        .then(() => {
          setIsSubmitting(false);
          setIsSubmitted(true);
          setStatus('success');
        })
        .catch((err) => {
          setIsSubmitting(false);
          setIsSubmitted(true);
          setStatus('fail');
        });
    } else {
      handleNext(values);
    }
  };

  const pageTitles = [
    'Personal details',
    'Work details',
    'Next of kin details',
  ];

  return (
    <>
      {isSubmitted ? (
        <SubmittedView
          status={status}
          goBack={() => {
            setPage(2);
            setIsSubmitted(false);
          }}
        />
      ) : (
        <div className="wrapper">
          <Form
            keepDirtyOnReinitialize
            onSubmit={handleSubmit}
            validate={validate}
            initialValues={values}
          >
            {({ handleSubmit, submitting, values }) => (
              <>
                <div className="form-top">
                  <p>
                    {pageTitles[page]}{' '}
                    <em>
                      {page + 1} of {children.length}
                    </em>
                  </p>
                  <div className="control-arrows">
                    <span
                      className="left-arrow"
                      onClick={() => handlePrevious()}
                    >
                      <img
                        src={page <= 0 ? ArrowLeftInactive : ArrowLeftActive}
                      />
                    </span>
                    <span
                      className="right-arrow"
                      onClick={
                        page >= children.length - 1
                          ? () => {}
                          : () => handleSubmit(values)
                      }
                    >
                      <img
                        src={
                          page >= children.length - 1
                            ? ArrowRightInactive
                            : ArrowRightActive
                        }
                      />
                    </span>
                  </div>
                </div>
                <form onSubmit={handleSubmit} className="cred-form">
                  <div className="form-content">
                    <motion.div
                      key={page}
                      initial={
                        entryDirection === 'fromRight'
                          ? { x: '100vw' }
                          : { x: '-100vw' }
                      }
                      animate={{ x: 0 }}
                      transition={{ type: 'tween', duration: 0.5 }}
                      exit={{ x: '-100vw' }}
                    >
                      {activePage}
                    </motion.div>
                  </div>
                  <div className="form-buttons">
                    {/* <button className="credup-btn credup-btn--white">
                      Save
                    </button> */}
                    <button
                      className="credup-btn  credup-btn--primary"
                      type="submit"
                    >
                      {isSubmitting
                        ? 'Submitting...'
                        : page >= children.length - 1
                        ? 'Apply now'
                        : 'Continue'}
                    </button>
                  </div>
                </form>
              </>
            )}
          </Form>
        </div>
      )}
    </>
  );
};

const FormPage = ({ children }) => {
  return children;
};

const required = (value) => (value ? undefined : 'Required!');

const truncateDigits = (value, length) =>
  value > length ? Number(String(value).slice(0, length)) : value;

const BorrowNow = () => {
  const [isPinkBg, setIsPinkBg] = useState(false);

  const handleChangeBg = (bool) => {
    setIsPinkBg(bool);
  };

  return (
    <Layout noFooter={true}>
      <div className="borrow-now-wrapper">
        <div className={`bg ${isPinkBg && 'pink'}`}></div>
        <FormWrapper onSubmit={onSubmit} makeBgPink={handleChangeBg}>
          <FormPage>
            <div className="field-50-50">
              <Field name="firstname" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      {...input}
                      type="text"
                      placeholder="First name"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="othername" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Other name"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="field-50-50">
              <Field name="lastname" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Last name"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="maidenName" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Mother's maiden name"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="field-70-30">
              <Field name="email" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="email"
                      placeholder="Email address"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="gender" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <select
                      type="text"
                      placeholder="Gender"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <p className="dob-heading">Date of birth</p>
            <div className="field-25-30-45">
              <Field name="day_of_birth" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="number"
                      placeholder="DD"
                      maxLength="2"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="month_of_birth" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="number"
                      placeholder="MM"
                      maxLength="2"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="year_of_birth" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="number"
                      placeholder="YYYY"
                      maxLength="2"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="field-45-30-25">
              <Field name="bvn" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="number"
                      placeholder="BVN"
                      maxLength="11"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="phone" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="number"
                      placeholder="Phone number"
                      maxLength="2"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field
                name="nationality"
                validate={required}
                initialValue="Nigeria"
              >
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <select
                      type="text"
                      placeholder="Gender"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    >
                      {countriesList.map((country) => (
                        <option value={country}>{country}</option>
                      ))}
                    </select>
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
          </FormPage>
          <FormPage>
            <div className="field-50-50">
              <Field name="occupation" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      {...input}
                      type="text"
                      placeholder="Occupation"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="job_title" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Job Title"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="field-50-50">
              <Field name="company" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Employer/Company"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="work_email" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="email"
                      placeholder="Work email address"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="field-100">
              <Field name="work_address" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <textarea
                      placeholder="Work address"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    ></textarea>
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
          </FormPage>
          <FormPage>
            <div className="field-50-50">
              <Field name="nok_firstname" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      {...input}
                      type="text"
                      placeholder="First name"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="nok_othername" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Other name"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="field-50-50">
              <Field name="nok_lastname" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      {...input}
                      type="text"
                      placeholder="Last name"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="nok_relationship" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Relationship"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="field-50-50">
              <Field name="nok_occupation" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      {...input}
                      type="text"
                      placeholder="Occupation"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="nok_company" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Employer/Company name"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="field-50-50">
              <Field name="nok_email" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      {...input}
                      type="email"
                      placeholder="Email address"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="nok_phone" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Phone number"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="field-70-30">
              <Field name="nok_bvn" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <input
                      type="number"
                      placeholder="BVN"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name="nok_gender" validate={required}>
                {({ input, meta }) => (
                  <div className="field-wrap">
                    <select
                      type="text"
                      placeholder="Gender"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="field-100">
              <Field name="nok_address" validate={required}>
                {({ input, meta }) => (
                  <div class="field-wrap">
                    <input
                      type="text"
                      placeholder="Residential address"
                      className={`cred-input ${
                        meta.touched && meta.error && `cred-input--error`
                      }`}
                      {...input}
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
          </FormPage>
        </FormWrapper>
      </div>
    </Layout>
  );
};

export default BorrowNow;
